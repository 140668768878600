<template>

  <div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="fetchUsers"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <b-card-body>

        <div class="d-flex align-items-center justify-content-end">
          <b-form-group
            class="mb-0 mr-1"
          >
            <b-input-group size="lg">
              <b-form-input
                v-model="searchQuery"
                type="search"
                :disabled="isBusy"
                placeholder="Type to Search"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group
            class="mb-0 mr-1"
          >
            <b-button
              variant="primary"
              size="lg"
              :disabled="isBusy"
              @click="isAddNewUserSidebarActive = true"
            >
              <span class="text-nowrap">Add User</span>
            </b-button>
          </b-form-group>
        </div>
      </b-card-body>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="users"
        responsive
        :fields="tableColumns"
        primary-key="userObjectID"
        show-empty
        empty-text="No matching records found"
        :no-border-collapse="true"
        sticky-header="80vh"
        :busy="isBusy"
        :filter="searchQuery"
        :filter-included-fields="filterOn"
      >

        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <b-spinner
              class="align-middle"
              variant="primary"
            />
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-edit', params: { id: data.item.userObjectID } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-edit', params: { id: data.item.userObjectID } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">{{ data.item.email }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(customerName)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.logoImage"
                :text="avatarText(data.item.customerName)"
                :variant="`light-${resolveUserRoleVariant(data.item.customerName)}`"
                :to="{ name: 'apps-customers-edit', params: { id: data.item.customerID } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-customers-edit', params: { id: data.item.customerID } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.customerName }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(siteNames)="data">
          <b-list-group flush>
            <b-list-group-item v-for="(item, index) in data.item.siteNames" :key="item">
              {{ index }}.{{ item }}
            </b-list-group-item>
          </b-list-group>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.archived)}`"
            class="text-capitalize"
          >
            {{ data.item.archived ? 'Archived' : 'Active' }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.userObjectID } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item> -->

            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.userObjectID } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteThisUser(data.item.userObjectID)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BSpinner, BCardBody, BInputGroup, BFormGroup, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    UserListAddNew,

    BCard,
    BCardBody,
    BInputGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BFormGroup,
    BListGroup,
    BListGroupItem,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchUsers,
      tableColumns,
      refUserListTable,
      searchQuery,
      isBusy,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      users,
      filterOn,
      deleteThisUser,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      searchQuery,
      isBusy,
      refUserListTable,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      users,
      filterOn,
      deleteThisUser,
    }
  },
}
</script>
